import debounce from 'lodash/debounce'
import { Modal } from './modal'
import { isInFrame } from '../utils'

const SELECTORS = {
    contentTemplate: '#js-nl-modal-content'
}

class NewsletterModal {
    constructor() {
        this.template = document.querySelector(SELECTORS.contentTemplate)

        if (!('localStorage' in window)) {
            console.warning(
                'cannot build newsletter modal, no localstorage support'
            )
            return
        }

        if (!this.template) {
            console.error(
                `cannot build newsletter modal, no content template found with id "${SELECTORS.contentTemplate}"`
            )
            return
        }

        this.init()
    }

    init() {
        this.lastDisplayTime = this.getLastDisplay()
        this.debouncedScrollListener = debounce(
            () => this.scrollListener(),
            100
        )

        if (!this.modal) {
            this.modal = new Modal('modal-newsletter-signup', {
                content: this.template.content,
                modalClassName: 'modal--small',
                dismissable: true,
                fadeIn: true
            })
        }

        this.setPageViews()
        this.registerListeners()
    }

    triggerModal() {
        this.modal.open()
        this.setLastDisplay()
        document.removeEventListener('scroll', this.debouncedScrollListener)
    }

    setPageViews() {
        // 2nd and 5th page are supposed to trigger the newsletter modal. So keep track
        // of that.
        // But only everywhere except the splitscreen page.
        if (['/en/', '/de/'].indexOf(window.location.pathname) > -1) {
            return
        }
        // Also, dont count any iframe openings.
        if (isInFrame()) {
            return
        }
        let count = this.getPageViews()
        count += 1
        this.pageViewCount = count

        if (count <= 10) {
            localStorage.setItem('pno', String(count))
        }
    }

    getPageViews() {
        const viewCount = parseInt(localStorage.getItem('pno') || 0, 10)
        if (isNaN(viewCount)) {
            return 0
        }
        return viewCount
    }

    setLastDisplay() {
        const timestamp = String(new Date().getTime())
        localStorage.setItem('pdat', timestamp)
    }

    getLastDisplay() {
        const milliseconds = parseInt(localStorage.getItem('pdat') || 0, 10)
        if (isNaN(milliseconds)) {
            return new Date()
        }
        return new Date(milliseconds)
    }

    registerListeners() {
        const now = new Date()
        let activateScrollListener = false

        if (this.pageViewCount === 2 || this.pageViewCount === 5) {
            activateScrollListener = true
        } else if (this.pageViewCount > 5) {
            // last displayed more than 2 weeks ago
            const daysSinceLastDisplay = Math.floor(
                (now - this.lastDisplayTime) / (1000 * 60 * 60 * 24)
            )
            if (daysSinceLastDisplay > 14) {
                activateScrollListener = true
            }
        }

        if (activateScrollListener) {
            document.addEventListener('scroll', this.debouncedScrollListener)
        }
    }

    scrollListener = () => {
        const doc = document.documentElement
        const bdy = document.body

        const scrollPercent =
            ((doc.scrollTop || bdy.scrollTop) /
                ((doc.scrollHeight || bdy.scrollHeight) - doc.clientHeight)) *
            100

        if (scrollPercent > 42) {
            this.triggerModal()
        }
    }
}

document.addEventListener('DOMContentLoaded', function () {
    new NewsletterModal()
})
