const SELECTORS = {
    header: '.js-header'
}

const isOutdatedBrowser = () => {
    const ua = window.navigator.userAgent
    const msie = ua.indexOf('MSIE ')
    const trident = ua.indexOf('Trident/')

    return msie > 0 || trident > 0
}

const buildUpgradeBanner = () => {
    const banner = document.createElement('div')
    const upgradeUrl = 'https://browsehappy.com/'
    const upgradeMessage = `You are using an outdated browser, so this website might not be displayed correctly. <a href="${upgradeUrl}">Get a modern browser here</a> to improve your experience.`

    banner.classList.add('header__banner')
    banner.setAttribute('data-nosnippet', true)
    banner.innerHTML = `<div class="container">${upgradeMessage}</div>`

    return banner
}

const checkBrowser = () => {
    if (isOutdatedBrowser()) {
        const header = document.querySelector(SELECTORS.header)
        header.prepend(buildUpgradeBanner())
    }
}

checkBrowser()
