import Plyr from 'plyr'
import { Modal } from './modal'

const SELECTORS = {
    trigger: '[data-video-modal]'
}

const TYPES = {
    youtube: 'youtube',
    vimeo: 'vimeo'
}

class VideoModal {
    constructor(trigger) {
        if (!trigger) {
            console.error('can not create videomodal, trigger element required')
        }

        this.trigger = trigger
        this.videoId = trigger.dataset.videoId
        this.modalId = `modal-${this.videoId}`
        this.playerType = trigger.dataset.playerType

        if (!this.videoId) {
            console.error('Error loading video: data-video-id is missing.')
        }
        if (!this.playerType) {
            console.error('Error loading video: data-player-type is missing.')
        }
        if (
            this.playerType &&
            this.playerType !== TYPES.youtube &&
            this.playerType !== TYPES.vimeo
        ) {
            console.error(
                `Error loading video: unknown player type "${this.playerType}".`
            )
        }
    }

    init() {
        const playerEl = this.makePlayerElement()
        this.modal = new Modal(this.modalId, {
            content: playerEl,
            modalClassName: 'modal--video',
            trigger: this.trigger,
            onOpen: this.handleOpen,
            onClose: this.handleClose
        })
    }

    handleOpen = () => {
        if (!this.player) {
            this.initPlayer()
        } else {
            this.play()
        }
    }

    handleClose = () => {
        this.pause()
    }

    makePlayerElement = () => {
        const playerEl = document.createElement('div')
        const playerId = `player-${this.videoId}`

        playerEl.setAttribute('id', playerId)
        playerEl.setAttribute('data-plyr-embed-id', this.videoId)
        playerEl.setAttribute('data-plyr-provider', this.playerType)

        this.playerId = playerId
        return playerEl
    }

    initPlayer = () => {
        if (this.playerId) {
            this.player = new Plyr(`#${this.playerId}`, {
                settings: [],
                autoplay: true
            })
        }
    }

    play = () => {
        if (this.player) {
            try {
                this.player.play()
            } catch (err) {
                console.error(`Error playing ${this.playerType} video:`, err)
            }
        }
    }

    pause = () => {
        if (this.player) {
            try {
                this.player.pause()
            } catch (err) {
                console.error(`Error pausing ${this.playerType} video:`, err)
            }
        }
    }
}

const modalTriggers = Array.from(document.querySelectorAll(SELECTORS.trigger))
if (modalTriggers.length) {
    modalTriggers.forEach((trigger) => {
        const videoModal = new VideoModal(trigger)
        videoModal.init()
    })
}
