/*
Cookie Consent Script
*/
import Cookies from 'js-cookie'
import { Modal } from './modal'
import { loadGTMScript, isInFrame } from '../utils'

const GTM_ID = 'GTM-MFXTGK8'
const SELECTORS = {
    consentWidget: '#js-cookieconsent-widget',
    content: '.js-cookieconsent-content',
    form: '.js-cookieconsent-form',
    checkbox: '.js-cookieconsent-checkbox',
    acceptBtn: '.js-cookieconsent-accept',
    saveBtn: '.js-cookieconsent-save',
    settingsBtn: '.js-cookieconsent-settings',
    editBtn: '.js-cookieconsent-edit'
}
const ALLOWED_HOSTS = {
    dev: '',
    prod: 'https://payuca.com'
}
const MESSAGES = {
    setConsent: 'SET_CONSENT'
}
const DEFAULT_SETTINGS = {
    required: true,
    statistics: false,
    marketing: false
}
const IS_PRODUCTION = window.location.hostname.includes('payuca.com')

// CONSENT WIDGET CLASS
// RUNS IN IFRAME INSIDE MODAL, PROVIDES UI FOR CONSENT
// =====================================================

class CookieConsentWidget {
    constructor(el, settings) {
        if (!el) {
            console.error(
                'cannot create CookieConsentWidget instance, no root element provided'
            )
            return null
        }

        this.els = {
            root: el,
            content: el.querySelector(SELECTORS.content),
            form: el.querySelector(SELECTORS.form),
            checkboxes: el.querySelectorAll(SELECTORS.checkbox),
            acceptBtn: el.querySelector(SELECTORS.acceptBtn),
            settingsBtn: el.querySelector(SELECTORS.settingsBtn),
            saveBtn: el.querySelector(SELECTORS.saveBtn)
        }

        this.settings = Object.assign(DEFAULT_SETTINGS, settings)
        this.inFrame = isInFrame()

        this.init()
    }

    init() {
        const { checkboxes, acceptBtn, settingsBtn, saveBtn } = this.els

        if (acceptBtn) {
            acceptBtn.addEventListener('click', this.acceptAll)
        }
        if (settingsBtn) {
            settingsBtn.addEventListener('click', this.showSettings)
        }
        if (saveBtn) {
            saveBtn.addEventListener('click', this.saveSettings)
        }
        if (checkboxes) {
            Array.from(checkboxes).forEach((cb) => {
                cb.addEventListener('change', this.updateSetting)
            })
        }

        if (this.inFrame) {
            this.setLinkTarget()
        }

        this.render()
    }

    updateSetting = (e) => {
        const { name, checked } = e.target
        if (!!name && this.settings[name] !== undefined) {
            const nextSettings = Object.assign({}, this.settings, {
                [name]: checked
            })
            this.settings = nextSettings
        }
    }

    setLinkTarget() {
        const links = this.els.root.querySelectorAll('a[href]')
        if (links.length) {
            Array.from(links).forEach((link) => {
                if (!link.getAttribute('target')) {
                    link.setAttribute('target', '_top')
                }
            })
        }
    }

    acceptAll = () => {
        const nextSettings = {}
        Object.keys(this.settings).forEach((key) => {
            nextSettings[key] = true
        })
        this.settings = nextSettings
        this.saveSettings()
    }

    showSettings = () => {
        const { content, form, saveBtn, settingsBtn } = this.els
        if (form) {
            form.removeAttribute('hidden')
            saveBtn.removeAttribute('hidden')
            settingsBtn.setAttribute('hidden', true)

            if (content) {
                content.scrollTop = content.scrollHeight
            }
        }
    }

    saveSettings = () => {
        if (this.inFrame) {
            const message = {
                type: MESSAGES.setConsent,
                payload: this.settings
            }
            window.top.postMessage(message, '*')
        } else {
            console.log(
                'cookie consent widget should not run in top window; no action taken'
            )
        }
    }

    render = () => {
        const { checkboxes } = this.els
        Array.from(checkboxes).forEach((cb) => {
            const checked = Boolean(this.settings[cb.name])
            cb.checked = checked
        })
    }
}

// MODAL / PARENT CLASS
// TRIGGERS THE OVERLAY AND SETS COOKIES ON THE TOP WINDOW
// =====================================================

class CookieConsent {
    constructor(options) {
        const defaults = {
            settingsCookieName: 'cookieconsent',
            expiryInDays: 365,
            onConsent: () => {}
        }

        this.opts = Object.assign(defaults, options)
        this.els = {
            consentWidget: document.querySelector(SELECTORS.consentWidget)
        }

        this.init()
    }

    init() {
        this.consent = this.getConsentCookie()
        window.addEventListener('message', this.handleMessage)

        if (this.els.consentWidget) {
            this.widget = new CookieConsentWidget(
                this.els.consentWidget,
                this.consent
            )
        } else {
            if (isInFrame()) {
                console.log('cookie consent overlay disabled inside iframe')
                return
            }
            if (window.__DISABLE_CONSENT_OVERLAY) {
                console.log(
                    'cookie consent overlay disabled bceause of __DISABLE_CONSENT_OVERLAY flag'
                )
                return
            }

            this.checkConsent()
        }
    }

    checkConsent() {
        if (!!this.consent) {
            // consent data found in cookie. interpret.
            this.opts.onConsent(this.consent)
        } else {
            // no valid consent data found. show modal
            this.open()
        }
    }

    updateConsent(consent) {
        if (!!consent && Object.keys(consent).length) {
            this.consent = consent
            this.setConsentCookie(consent)
            this.opts.onConsent(consent)
        }
    }

    getConsentCookie() {
        // read previously set cookie settings
        const { settingsCookieName } = this.opts
        const cookieValue = Cookies.get(settingsCookieName)

        // we have *something*
        if (cookieValue) {
            const settingsString = window.decodeURIComponent(cookieValue)
            const data = {}

            // decode cookie value into an object of flags, e.g.
            // { a: true, b: false, c: false }
            settingsString.split('|').forEach((entry) => {
                if (entry.includes(':')) {
                    const [key, value] = entry.split(':')
                    const flag = Boolean(parseInt(value, 10))
                    data[key] = flag
                }
            })

            return data
        }

        return undefined
    }

    setConsentCookie(data) {
        // set cookie value for consent
        const { settingsCookieName, expiryInDays } = this.opts
        const encodeEntry = ([key, value]) => {
            const val = Boolean(value) ? 1 : 0
            return `${String(key)}:${val}`
        }

        // cookie value is not encoded as JSON for compat reasons.
        // instead the values are stored as a string like a:1|b:0|c:0
        const settingsString = Object.entries(data).map(encodeEntry).join('|')
        const domain = IS_PRODUCTION ? 'payuca.com' : undefined
        Cookies.set(settingsCookieName, settingsString, {
            expires: expiryInDays,
            domain: domain
        })
    }

    handleMessage = (event) => {
        // if (!Object.values(ALLOWED_HOSTS).includes(event.origin)) {
        //     // safety check, might not work with subdomain later, revisit.
        //     console.log(
        //         'iframe message blocked because of unknown origin',
        //         event.origin
        //     )
        //     return
        // }

        if (event.data && event.data.type) {
            // optional: handle other message types in here
            if (event.data.type === MESSAGES.setConsent) {
                const isInitialConsent = this.consent === undefined

                this.updateConsent(event.data.payload)
                this.modal.close()

                if (!isInitialConsent) {
                    window.location.reload()
                }
            }
        }
    }

    makeModal() {
        // build the iframe that will serve as the cookie consent modal content.
        const baseUrl = IS_PRODUCTION ? ALLOWED_HOSTS.prod : ALLOWED_HOSTS.dev
        const locale = document.documentElement.getAttribute('lang') || 'de'
        let frameSourceUrl = `${baseUrl}/${locale}/b2b/cookie-consent/`
        if (window.location.hostname === 'app.payuca.com') {
            frameSourceUrl += '?theme=b2c'
        }

        const iframe = document.createElement('iframe')
        iframe.id = 'iframe-cookie-consent'
        iframe.src = frameSourceUrl
        iframe.setAttribute('width', 640)
        iframe.setAttribute('height', 480)
        iframe.setAttribute('frameborder', 0)

        // create the modal instance from the defauult Modal class
        const modal = new Modal('modal-cookie-consent', {
            content: iframe,
            modalClassName: 'modal--iframe',
            namespace: 'pyc',
            dismissable: false
        })

        return modal
    }

    open() {
        if (!this.modal || typeof this.modal.open !== 'function') {
            this.modal = this.makeModal()
        }
        this.modal.open()
    }
}

// ROOT
// =====================================================

document.addEventListener('DOMContentLoaded', function () {
    const cookieConsent = new CookieConsent({
        // callback fired when at least 'required' consent is given
        onConsent: function (consent) {
            // param consent: map of specific consent flags,
            // e.g. { required: true, marketing: false, statistics: false }
            // async load tag manager
            if (consent.marketing || consent.statistics) {
                loadGTMScript(GTM_ID)
            }
        }
    })

    const editConsentBtn = document.querySelectorAll(SELECTORS.editBtn)
    editConsentBtn.forEach((btn) => {
        btn.addEventListener('click', () => cookieConsent.open())
    })
})
