// Vanilla JS Module Index

// EXTERNAL PACKAGES
// focus-visible polyfill, for proper focus styling
import 'focus-visible'

// STATIC MODULES
// common modules, included on every page
// using standard ES6 imports here because no codesplitting intended
import './modules/header'
import './modules/navigation'
import './modules/langselect'
import './modules/videomodal'
import './modules/players'
import './modules/browsernotice'
import './modules/vh'

import './modules/cookieconsent'
import './modules/newslettermodal'

// DYNAMIC MODULES
// Index of {filename}: {js hook}
// JS Hooks have to be '.js-' prefixed classes/ids
const MODULES = {
    infinitescroll: '.js-infinitescroll',
    lazyload: '.js-lazyload',
    textfield: '.js-textfield',
    accordion: '.js-accordion',
    slider: '.js-slider',
    expandinglist: '.js-expandinglist',
    tabs: '.js-tabs'
}

const noop = () => {}
Object.keys(MODULES).forEach((name) => {
    if (document.querySelector(MODULES[name])) {
        import(`./modules/${name}`).then(noop)
    }
})
