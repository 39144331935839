const SELECTORS = {
    langselect: '.js-langselect'
}

const onLanguageChange = (event) => {
    const locale = event.target.value
    const selectedOption = Array.from(
        event.target.querySelectorAll('option')
    ).filter((option) => option.value === locale)

    if (selectedOption && selectedOption[0]) {
        const url = selectedOption[0].dataset.url
        if (url) {
            window.location.href = url
        }
    }
}

const langselects = document.querySelectorAll(SELECTORS.langselect)
if (langselects) {
    langselects.forEach((el) => {
        const selectInput = el.querySelector('select')
        selectInput.addEventListener('change', onLanguageChange)
    })
}
