import throttle from 'lodash/throttle'
import { getScrollY } from '../utils'

const SELECTORS = {
    header: '.js-header'
}
const CLASSES = {
    scrolled: 'header--scrolled'
}

const header = document.querySelector(SELECTORS.header)
const checkScrollPosition = () => {
    const scrollPos = getScrollY()
    const treshold = 50
    if (scrollPos > treshold) {
        header.classList.add(CLASSES.scrolled)
    } else {
        header.classList.remove(CLASSES.scrolled)
    }
}
if (header) {
    window.addEventListener('scroll', throttle(checkScrollPosition, 100))
}
