import Plyr from 'plyr'

const SELECTORS = {
    players: '.js-player'
}

const playerElements = document.querySelectorAll(SELECTORS.players)
if (playerElements) {
    const opts = {
        settings: []
    }
    Array.from(playerElements).map((p) => new Plyr(p, opts))
}
