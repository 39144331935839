import { createFocusTrap, onClickOutsideOf } from '../utils'

const CLASSES = {
    active: 'is-active',
    blurred: 'is-blurred',
    noScroll: 'no-scroll',
    fadeIn: 'fade-in'
}

export class Modal {
    constructor(id, options) {
        const defaults = {
            content: undefined,
            trigger: undefined,
            modalClassName: undefined,
            namespace: undefined,
            dismissable: true,
            fadeIn: false
        }

        if (!id) {
            console.error('unable to create modal instance, no id provided')
            return
        }

        this.id = id
        this.els = {}
        this.opts = Object.assign(defaults, options)

        this.init()
    }

    buildElements() {
        const ns = this.opts.namespace ? `${this.opts.namespace}-` : ''
        const classNames = {
            modal: `${ns}modal`,
            overlay: `${ns}modal__overlay`,
            controls: `${ns}modal__controls`,
            content: `${ns}modal__content`,
            close: `${ns}modal__close`
        }

        const root = document.createElement('div')
        root.setAttribute('id', this.id)
        root.setAttribute('hidden', true)
        if (this.opts.fadeIn) {
            root.classList.add(CLASSES.fadeIn)
        }

        const overlay = document.createElement('div')
        overlay.classList.add(classNames.overlay)

        const modal = document.createElement('div')
        modal.classList.add(classNames.modal)
        if (this.opts.modalClassName) {
            modal.classList.add(`${ns}${this.opts.modalClassName}`)
        }
        modal.setAttribute('tabindex', '-1')
        modal.setAttribute('role', 'dialog')

        const contentContainer = document.createElement('div')
        contentContainer.classList.add(classNames.content)

        const controls = document.createElement('div')
        controls.classList.add(classNames.controls)

        let closeBtn
        if (this.opts.dismissable) {
            closeBtn = document.createElement('button')
            closeBtn.setAttribute('type', 'button')
            closeBtn.setAttribute('aria-label', this.getMessage('close'))
            closeBtn.classList.add(classNames.close)
            closeBtn.innerHTML = `
        <svg class="icon icon--close" aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M18.71 6.71l-1.42-1.42-5.29 5.3-5.29-5.3-1.42 1.42 5.3 5.29-5.3 5.29 1.42 1.42 5.29-5.3 5.29 5.3 1.42-1.42-5.3-5.29 5.3-5.29z"/>
        </svg>
        `
            controls.appendChild(closeBtn)
        }

        let triggerBtn
        if (this.opts.trigger) {
            triggerBtn = this.opts.trigger
        }

        if (this.opts.content) {
            contentContainer.appendChild(this.opts.content)
        }

        modal.appendChild(controls)
        modal.appendChild(contentContainer)
        overlay.appendChild(modal)
        root.appendChild(overlay)

        this.els = {
            root,
            modal,
            closeBtn,
            triggerBtn,
            contentContainer
        }

        document.body.appendChild(root)
    }

    init() {
        this.buildElements()

        this.focusTrap = createFocusTrap(this.els.modal, {
            toggleElement: this.els.triggerBtn,
            onEscape: this.close,
            moveInitialFocus: true
        })

        this.bindEvents()
    }

    bindEvents() {
        if (this.els.triggerBtn) {
            this.els.triggerBtn.addEventListener('click', this.open)
        }
        if (this.opts.dismissable) {
            this.els.closeBtn.addEventListener('click', this.close)
        }
    }

    open = () => {
        const setActiveClasses = () => {
            this.els.root.classList.add(CLASSES.active)
            document.body.classList.add(CLASSES.noScroll, CLASSES.blurred)
        }

        this.els.root.removeAttribute('hidden')
        this.focusTrap.activate()

        if (this.opts.fadeIn) {
            window.setTimeout(setActiveClasses, 5)
        } else {
            setActiveClasses()
        }

        if (this.opts.dismissable) {
            window.setTimeout(() => {
                this.outsideClickHandler = onClickOutsideOf(
                    this.els.modal,
                    this.close
                )
            }, 5)
        }

        if (this.opts.onOpen) {
            this.opts.onOpen()
        }
    }

    close = () => {
        this.els.root.setAttribute('hidden', true)
        this.els.root.classList.remove(CLASSES.active)
        document.body.classList.remove(CLASSES.noScroll, CLASSES.blurred)

        this.focusTrap.deactivate()

        if (this.outsideClickHandler) {
            document.removeEventListener('click', this.outsideClickHandler)
        }

        if (this.opts.onClose) {
            this.opts.onClose()
        }
    }

    getMessage = (id) => {
        const locale = document.documentElement.getAttribute('lang') || 'de'
        const messages = {
            close: {
                de: 'schließen',
                en: 'close'
            }
        }

        if (!messages[id]) {
            return ''
        }

        return messages[id][locale]
    }
}

export default Modal
